import {HTTP} from "../env";
import axios from "axios";

export const allEvents = () => {
  const options = {
    method: 'GET',
    url: HTTP + '/allevents',
    headers: {'content-type': 'application/json'},
  };

  return new Promise((resolve, reject) => {
    axios(options).then(response => {
      resolve(response.data.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export const SingleEvent = (id) => {
  const options = {
    method: 'GET',
    url: HTTP + '/final',
    headers: {'content-type': 'application/json'},
    params: {event_id: id}
  };

  return new Promise((resolve, reject) => {
    axios(options).then(response => {
      resolve(response.data.data);
    }).catch(error => {
      reject(error);
    })
  })
}

export const getEvents = () => {
  const options = {
    method: 'GET',
    url: HTTP + '/getevents',
    headers: {'content-type': 'application/json'},
  };

  return new Promise((resolve, reject) => {
    axios(options).then(response => {
      resolve(response.data.data);
    }).catch(error => {
      reject(error);
    })
  })
}
