<template>
  <div class="w-full px-2">
    <div class="flex flex-wrap -mx-2">
      <div class="w-full xs:w-full bg sm:w-full px-2 mb-2">
        <div class="vx-row bg-white w-full  mb-4">
          <div class="w-full p-2">
            <div class="vl">
              <span class="text-black ml-2 text-2xl font-bold">
                Final Score
              </span>
            </div>
          </div>
        </div>
        <TableDefault/>

      </div>
    </div>
  </div>
</template>
<script>
import Table from "./ui-elements/table/Table";
import TableDefault from "./ui-elements/table/TableDefault";
export default {
  components: {TableDefault, Table}
}
</script>
